import React from 'react';
import Footer from '../components/Layout/footer';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import SEOHeader from '../components/Head';

const Contact: React.FC = () => (
    <>
        <SEOHeader title="Pierre Buzulier - Contact" />
        <main className="container mx-auto p-6 min-h-screen relative pb-24">
            <div className="relative p-16 lg:p-36 lg:pb-0 font-serif md:text-xl lg:text-2xl flex flex-col gap-8">
                <Link to="/" className="absolute opacity-0 lg:opacity-100 top-8 right-0">
                    <StaticImage src="../images/cross.png" alt="close" width={20} height={20} />
                </Link>
                <p>art director</p>
                <div className="inline-flex flex-col lg:flex-row flex-wrap">
                    <span className="inline-block w-full lg:w-auto">professional experience&nbsp;</span>
                    <div className="flex-col lg:flex-row">
                        <span className="inline-block w-full lg:w-auto">
                            <strong>Havas Worldwide &nbsp;-&nbsp;</strong>2008
                        </span>
                        <span className="hidden lg:inline">&nbsp;/&nbsp;</span>
                        <span className="inline-block w-full lg:w-auto">
                            <strong>Freelance&nbsp;-&nbsp;</strong>2014
                        </span>
                        <span className="hidden lg:inline">&nbsp;/&nbsp;</span>
                        <span className="inline-block w-full lg:w-auto">
                            <strong>Mindoza&nbsp;-&nbsp;</strong>2017
                        </span>
                    </div>
                </div>
                <p>
                    formation
                    <br />
                    <strong>école de condé - Marseille</strong>
                </p>
                <p>
                    skills
                    <br />
                    art direction, illustration, copywriting, film
                    <br />
                    photo manipulation, photoshop, retouching
                </p>
                <div className="font-sans flex flex-col gap-4 items-start lg:items-end">
                    <a href="mailto:pierre.buzulier@gmail.com">pierre.buzulier@gmail.com</a>
                    <a href="https://www.instagram.com/pierrebuzulier/" target="_blank" rel="noreferrer">
                        <StaticImage src="../images/insta.svg" alt="instagram" />
                        pierrebuzulier
                    </a>
                </div>
            </div>
        </main>
        <Footer />
    </>
);

export default Contact;
